import { Injectable } from '@angular/core';
import { gql, Query } from 'apollo-angular';

import { PatientUIMarkRegAsyncToMarkRegCompleteEdge } from '@app/registration/graph/edges/__generated__/mark-reg-async-to-mark-reg-complete-edge-graphql.service.types';

@Injectable({
  providedIn: 'root',
})
export class MarkRegAsyncToMarkRegCompleteEdgeGraphqlService extends Query<PatientUIMarkRegAsyncToMarkRegCompleteEdge> {
  document = gql`
    query PatientUIMarkRegAsyncToMarkRegCompleteEdge {
      patient {
        id
        isRegComplete
      }
    }
  `;
}
