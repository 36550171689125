import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { MarkRegAsyncToMarkRegCompleteEdgeGraphqlService } from '@app/registration/graph/edges/mark-reg-async-to-mark-reg-complete-edge-graphql.service';
import { RegistrationEdge } from '@app/registration/graph/graph-navigation.service';
import { MarkRegCompleteNode } from '@app/registration/graph/nodes/mark-reg-complete.node';

@Injectable({
  providedIn: 'root',
})
export class MarkRegAsyncToMarkRegCompleteEdge implements RegistrationEdge {
  constructor(private graphQLService: MarkRegAsyncToMarkRegCompleteEdgeGraphqlService) {}

  nextNode = MarkRegCompleteNode;

  shouldNavigate$(): Observable<boolean> {
    return this.graphQLService
      .fetch({}, { fetchPolicy: 'network-only' })
      .pipe(map(({ data: { patient } }) => !patient.isRegComplete));
  }
}
