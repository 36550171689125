import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { MarkRegAsyncToHomepageEdge } from '@app/registration/graph/edges/mark-reg-async-to-homepage.edge';
import { MarkRegAsyncToMarkRegCompleteEdge } from '@app/registration/graph/edges/mark-reg-async-to-mark-reg-complete.edge';
import { NodeUrlMappingService } from '@app/registration/graph/node-url-mapping.service';
import { MarkRegCompleteGraphQLService } from '@app/registration/graph/nodes/mark-reg-complete-graphql.service';
import { RegistrationNode } from '@app/registration/graph/nodes/registration.node';

@Injectable({
  providedIn: 'root',
})
export class MarkRegAsyncNode extends RegistrationNode {
  constructor(
    markRegAsyncToHomepageEdge: MarkRegAsyncToHomepageEdge,
    markRegAsyncToMarkRegCompleteEdge: MarkRegAsyncToMarkRegCompleteEdge,
    nodeUrlMappingService: NodeUrlMappingService,
    private graphQLService: MarkRegCompleteGraphQLService,
    router: Router,
  ) {
    super(nodeUrlMappingService, router);
    this.edges = [markRegAsyncToMarkRegCompleteEdge, markRegAsyncToHomepageEdge];
    this.isAsync = true;
  }

  execute$(): Observable<void> {
    return this.graphQLService.mutate().pipe(map(() => undefined));
  }
}
